/* Common styles for both light and dark mode */
.container {
  display: flex;
  min-height: 100vh;
}

.header {
  margin: 20px;
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 990;
  cursor: default;
}

.sidebar {
  width: 176px;
  padding: 90px 10px 10px 15px;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 985;
  cursor: default;
  transition: 0.3s ease-in-out;
  overflow-y: auto;
}

.sidebar .sidebar-content {
  opacity: 1;
  transition: 0s ease-in-out, 0.3s ease-in-out 0.3s;
}

.sidebar.collapsed .sidebar-content {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

.cylhr {
  margin-top: -10px;
}

@media (max-width: 475px) {
  .popup {
    max-width: 260px;
    z-index: 1010;
  }

  .modal-body {
    max-height: 65vh;
  }
}

@media (max-width: 767px) {
  /* Collapse sidebar width on smaller screens */
  .sidebar.collapsed {
    width: 0;
  }

  /* Hide scrollbar when sidebar is collapsed */
  .sidebar.collapsed {
    overflow: hidden;
  }

  .popup {
    width: 380px;
    z-index: 1010;
    /* margin-left: 20px; */
  }

  .cyl-display-popup {
    width: 200px;
    margin-left: 0;
  }

  .cylhr {
    margin-top: 1px;
  }
}

@media (min-width: 767px) {
  .popup {
    width: 460px;
    z-index: 1010;
    /* margin-left: 120px; */
    max-height: 85vh;
  }

  .modal-body {
    max-height: 65vh;
  }
}

h4 {
  margin-bottom: 5px;
}

.sidebarOption {
  margin-bottom: 5px;
}

.sidebarOption label {
  cursor: pointer;
}

.sidebarCheckbox {
  padding-right: 10px;
  cursor: pointer;
}

.main {
  flex: 1;
  padding: 0 20px 60px 20px;
  /* margin-left: 200px; */
  /* margin-right: 20px; */
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table-container {
  /* margin: 20px 200px 20px 250px; */
  max-width: 70%;
  overflow-x: auto;
  overflow-y: auto;
  padding-top: 20px;
  display: flex; /* Use flexbox layout */
  align-items: center; /* Align items vertically */
  flex: 1;
}

.table-wrapper {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Align items vertically */
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table th:not(:first-child),
.table td:not(:first-child) {
  border-left: 1px solid #dddddd;
}

#replacement-header {
  /* max-width: 10px; */
  padding-left: 2px;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: box-shadow 0.2s ease-in-out; /* Add smooth transition effect */
}

td {
  text-align: center;
  position: relative;
}

#name-column {
  max-width: 450px;
}

#toric-header {
  text-align: center;
  width: 20px;
}

#toric-column {
  min-width: 20px;
}

#multifocal-header {
  text-align: center;
  min-width: 20px;
}

#multifocal-column {
  width: 20px;
}

#replacement-header {
  padding-left: 7px;
  text-align: center;
}

#replacement-column {
  text-align: center;
  max-width: 90px;
}

#bc-header {
  text-align: center;
}

#bc-column {
  text-align: center;
  width: 90px;
}

#diam-header {
  text-align: center;
}

#diam-column {
  text-align: center;
  max-width: 90px;
}

.circle {
  text-align: center;
  position: relative;
}

.circle::before {
  content: attr(data-label);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.circle.toric::before,
.circle.multifocal::before {
  content: attr(data-label);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.toric::before {
  background-color: #007bff;
  color: white;
}

.multifocal::before {
  background-color: #28a745;
  color: white;
}

/* Media query for smaller viewports */
@media (max-width: 767px) {
  /* Hide text inside circles */
  .circle.toric::before,
  .circle.multifocal::before {
    content: "";
  }

  /* Reduce size of circles to become small colored dots */
  .circle.toric::before,
  .circle.multifocal::before {
    width: 15px;
    height: 15px;
    padding: 0; /* Remove padding */
  }
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  margin: 10px 0 0 50px;
  color: #333;
}

h3 span {
  font-size: 56px;
  color: #007bff;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.toggle-container {
  position: fixed;
  top: 10px;
  right: 26px;
  display: flex;
  align-items: center;
  z-index: 996;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: transform 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #28a745; /* Green background when toggled */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.no-match-message {
  font-size: 1.6em;
}

/* Dark mode styles */
.dark-mode .main {
  background-color: #00005c;
  color: lightgray;
}

.dark-mode .header {
  background-color: #00005c;
  z-index: 950;
}

.dark-mode .header h3 {
  color: #fff;
}

.dark-mode .sidebar {
  background-color: #222;
  color: white;
}

.dark-mode .table th,
.dark-mode .table td {
  border-color: lightcyan;
}

.dark-mode .mode-text {
  color: #28a745;
}

.dark-mode .slider {
  background-color: #555;
}

/* card */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 4px outset rgb(0, 0, 255, 0.3);
  border-radius: 15px;
  padding: 20px;
}

.popup h4 {
  margin-top: -2px;
  margin-right: 10px;
  font-weight: 900;
}

.property {
  margin: 5px;
}

.property .label,
.modal-body .label {
  font-weight: 600;
  margin-right: 5px;
}

.dark-mode tbody tr:hover {
  box-shadow: 0 0 15px rgba(127, 127, 255, 0.4);
  transition: box-shadow 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s ease-in-out;
}

.dark-mode .popup {
  background-color: lightgray;
  border: 4px outset green;
  color: black;
}

.modal-body {
  background-color: lightgray;
  padding: 15px 15px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.close {
  text-decoration: none;
  font-size: 24px;
  color: gray;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  text-align: center;
}

.close:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
  transition: box-shadow 0.2s ease-in-out; /* Add smooth transition effect */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to top, #007bff, #6fc0f6);
  height: 80px;
  padding: 15px 15px 15px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  color: white;
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.empty-div {
  flex: 1; /* Each empty div takes equal space */
}

.footer-column p {
  margin: 0;
}

.footer-column:first-child {
  text-align: left;
}

.footer-column:last-child {
  text-align: right;
}

.dark-mode .footer {
  background: linear-gradient(to top, #3e0150, #8328b3);
}

.exam-mode {
  background-color: #1c1c1c;
  color: #555353;
}

.exam-mode .table th,
.exam-mode .table td {
  border-color: #dddddd;
}

.exam-mode .sidebar {
  background-color: #222;
  color: #737373;
}

.exam-mode .sidebar button {
  background-color: #222;
  color: #747070;
}

.exam-mode .footer {
  background: linear-gradient(to top, #000a14, #001a2b);
  color: gray;
}

.toggle-container2 {
  position: fixed;
  top: 50px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 996;
}

.toggle-icon {
  opacity: 20%;
  padding-right: 5px;
  margin-top: 18px;
}

.toggle-icon:hover {
  color: #007bff;
  opacity: 0.8;
  padding-right: 5px;
  margin-top: 18px;
  text-shadow: 0 0 10px #007bff;
}

.slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.4s;
  border-radius: 34px;
}

.slider2:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: transform 0.4s;
  border-radius: 50%;
}

input:checked + .slider2 {
  background-color: #002e0b; /* Green background when toggled */
}

input:checked + .slider2:before {
  transform: translateX(26px);
}

.slider2.round {
  border-radius: 34px;
}

.exam-mode .toric::before {
  background-color: #007bff36;
  color: rgba(255, 255, 255, 0.26);
}

.exam-mode .multifocal::before {
  background-color: #28a74642;
  color: rgba(255, 255, 255, 0.264);
}

.exam-mode h3 span {
  color: #ffd0008b;
}

.exam-mode .sidebar {
  opacity: 0.5;
}

.exam-mode input:checked + .slider {
  background-color: #28a74627; /* Green background when toggled */
}

.exam-mode .slider {
  background-color: rgba(255, 255, 255, 0.356);
}

.exam-mode .slider:before {
  background-color: rgba(255, 255, 255, 0.705);
}

.fa {
  font-size: 24px;
}

.chevron-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.chevron-icon {
  font-size: 24px;
}

.chevron-rotated {
  transform: rotate(180deg);
}

.chevron-normal {
  transform: rotate(0deg);
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -20px;
}

#sph-input {
  margin-left: 15px;
}

.input-container-cyl {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
  max-height: 40px;
}

.input-container-cyl h4,
.input-container-axis h4 {
  margin-right: 22px;
  margin-bottom: 20px;
}

.input-container-axis,
.input-container-axis h5,
#fivedeg {
  display: inline-flex;
  align-items: center;
}

.input-container-axis h4 {
  margin-right: 16px; /* Adjust margin as needed */
  margin-bottom: 20px;
}

#axislabel {
  margin-bottom: 10px;
}

.dropdown {
  margin-top: 8px;
}

#fivedeg {
  margin-top: -5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

#fivedegtext {
  margin-left: 5px;
  font-size: smaller;
}

.clear-button,
.additional-button {
  margin-top: 15px;
  padding: 5px 10px;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor on hover */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.additional-button {
  margin-top: 9px;
  margin-left: 4px;
  margin-bottom: 5px;
}

.clear-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.clear-button:focus {
  outline: none; /* Remove focus outline */
}

.additional-popup span {
  font-size: smaller;
}

#sca-clear-button {
  transform: translateY(-4px);
}

/* .clear-all-button {
  margin-top: 15px;
} */

.clear-all-button {
  margin-top: 15px;
  padding: 7px 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-all-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.clear-all-button:focus {
  outline: none; /* Remove focus outline */
}

@keyframes glow-dark {
  0% {
    text-shadow: 0 0 16px darkorchid, 0 0 12px darkorchid, 0 0 18px darkorchid,
      0 0 24px darkorchid, 0 0 30px darkorchid;
  }
  100% {
    text-shadow: 0 0 36px black;
  }
}

.sidebar-section {
  border-bottom-style: solid;
  border-right-style: solid;
  border-bottom-color: lightgray;
  border-right-color: lightgray;
  overflow: hidden;
  margin-top: 10px;
  padding-bottom: 7px;
}

.section-content {
  overflow: hidden;
  max-height: 1000px; /* Set a high initial max-height */
  transition: 0.3s ease-in-out; /* Transition max-height and visibility */
  visibility: visible; /* Ensure content is visible by default */
}

.sidebar-section.collapsed .section-content {
  max-height: 0;
  visibility: hidden;
}

.sidebar-section .section-content .sph-title {
  margin-top: 5px;
  padding-top: 5px;
}

.dark-mode .sidebar-section {
  border-bottom-style: solid;
  border-right-style: solid;
  border-bottom-color: darkorchid;
  border-right-color: darkorchid;
}

.dark-mode .sidebar-section h4:hover {
  animation: glow-dark 2s alternate ease-in-out;
}

.exam-mode .sidebar-section {
  border-bottom-style: solid;
  border-right-style: solid;
  border-bottom-color: #1a1a1a;
  border-right-color: #1a1a1a;
}

.no-click table {
  pointer-events: none;
}

.sidebar-section-label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.power-buttons-container button {
  border: none;
  outline: none;
  background: none;
  cursor: default;
  user-select: none; /* Prevent text selection on double-click */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
}

.power-buttons-container button:hover {
  /* Remove hover effect */
  background-color: transparent; /* Change to whatever background color you want */
}

.power-buttons-container button:active {
  /* Remove click effect */
  transform: none; /* Remove any active state transformations */
}

.power-section h5 {
  margin: 10px 0 5px 0;
}

.modal-body .label {
  margin: 0 5px 10px 0;
}

.power-text {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 8px;
}

.small-info {
  display: inline;
}

.small-label {
  display: inline;
  font-weight: bold;
  margin-left: 116px;
}

.small-value {
  display: inline;
  font-size: 12px;
  margin-left: 3px;
}

.popupSphere .power-section span {
  display: inline-block;
}

#bc-spacer {
  font-size: large;
  font-weight: bold;
  margin-left: 7px;
}

#bc-label {
  font-size: smaller;
  margin-left: 12px;
}

#diam-spacer {
  font-size: large;
  font-weight: bold;
  margin-left: 7px;
}

#diam-label {
  font-size: smaller;
  margin-left: 9px;
}

.popup hr {
  opacity: 0.25;
}

/* Dark mode popup styles */
.dark-mode .popup {
  background-color: #333;
  color: #ddd;
  border-color: darkorchid;
}

.dark-mode .modal-body {
  background-color: #00005c;
}

.dark-mode .power-buttons-container button {
  color: lightgray;
}

.dark-mode .power-button.inactive {
  color: gray;
}

.dark-mode .close:hover {
  animation: glow-dark 2s alternate ease-in-out;
}

/* Exam mode popup styles */
.exam-mode .popup {
  background-color: #333;
  color: #bbb;
  border-color: #555;
}

.exam-mode .modal-body {
  background-color: #222;
}

.exam-mode .power-buttons-container button {
  color: #777;
}

.exam-mode .power-button.inactive {
  color: #444;
}

.addSpan {
  font-size: smaller;
}

.xrText {
  font-size: small;
  padding-top: 2px;
  padding-bottom: 2px;
}

.minusText {
  margin-top: 11px;
  margin-bottom: 8px;
}

.cylProperty {
  overflow: auto;
}

.cylProperty button {
  margin-left: 5px; /* Adjust as needed */
  margin-bottom: 5px;
}

.cylPopUpLabel {
  float: left;
  width: 60px;
  padding-bottom: 5px;
  width: fit-content;
}

/* shares className with inline styling in SmallLensSphProperties */
.spanTextPC {
  font-size: 10px;
}

.smallPCXRMF h5 {
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 6px;
}

.smallPCXRMF h6 {
  font-weight: 500;
  font-size: smaller;
  margin-top: 4px;
  margin-bottom: 4px;
}

.cyl-display-popup .popup-content h4 {
  margin: 10px 0 5px 0;
}

.cylDisplaySpan {
  font-size: 15px;
}

.cyl-content h5 {
  margin: 0;
}

.cylSpan {
  font-weight: bold;
  font-size: 13px;
}

.cylSphDiv {
  margin-top: 10px;
  margin-left: 5px;
}

.cylRange1 {
  margin-left: 5px;
  margin-bottom: 5px;
}

.cylRange2 {
  margin-left: 5px;
}

.notice-container {
  display: flex;
  justify-content: center;
}

.notice-value {
  margin-top: 5px;
  font-size: small;
}

#g2span {
  margin-left: 43px;
}

#g4span {
  margin-left: 16px;
}

.materialhr {
  opacity: 0.15;
  width: 93%;
  margin-left: 3px;
}

#dktspan {
  margin: 0 0 5px 5px;
  font-weight: 500;
}

#lens-type-row2 {
  margin-top: 8px;
}

#ew-label {
  margin-left: 9px;
  font-size: smaller;
}

/* CSS for the overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Semi-transparent black */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for opacity changes */
}

.ad-container {
  position: fixed;
  right: 6px;
}

/* CSS to display the overlay when hovering over the parent container */
.ad-container:hover .overlay {
  opacity: 0.1; /* Show the overlay on hover */
}

.ad-container-small {
  width: 80px; /* Default width for small screens */
}

@media (max-width: 440px) {
  .ad-container-small {
    width: 60px; /* Adjusted width for extra small screens */
  }
}

/* Additional styles for the image (optional) */
.image {
  display: block; /* Ensure the image fills the container */
  max-width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}

.exam-mode .ad-container .overlay {
  opacity: 0.5; /* Dim the image when in exam mode */
}

a:active {
  color: red; /* Change the color slightly while being clicked */
}

/* CSS */
input[type="radio"] {
  width: 12px;
  height: 12px;
  vertical-align: top;
  margin-top: 5px;
}

.privateOption {
  margin: 0;
}

.store-card {
  position: fixed;
  max-width: 350px;
  top: 400px;
  left: 386px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 20px 15px 20px;
  border: 4px outset rgb(0, 0, 255, 0.3);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1200;
  display: none; /* Initially hide the StoreCard */
}

.store-card.show {
  display: block; /* Show the StoreCard when 'show' prop is true */
}

.store-content {
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
